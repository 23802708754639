import {
    faXTwitter as fabXTwitter,
    faLinkedin as fabLinkedin,
    faFacebook as fabFacebook,
    faInstagram as fabInstagram
} from '@fortawesome/free-brands-svg-icons'

import {
    faAngleDown as falAngleDown,
    faArrowRight as falArrowRight,
    faArrowUpRight as falArrowUpRight,
    faCheck as falCheck,
    faCircle as falCircle,
    faList as falList,
    faEnvelope as falEnvelope,
    faPhone as falPhone,
    faTimes as falTimes,
} from '@fortawesome/pro-light-svg-icons'

import {
    faArrowLeft as farArrowLeft,
    faArrowRight as farArrowRight,
    faBars as farBars,
    faTimes as farTimes,
    faCircleArrowRight as farCircleArrowRight,
} from '@fortawesome/pro-regular-svg-icons'

import {
    faBars as fasBars,
    faMinus as fasMinus,
    faPhone as fasPhone,
    faPlus as fasPlus,
    faStar as fasStar,
    faTimes as fasTimes,
} from '@fortawesome/pro-solid-svg-icons'

export const icons = {
    fabFacebook,
    fabInstagram,
    fabLinkedin,
    fabXTwitter,

    falList,
    falAngleDown,
    falArrowRight,
    falArrowUpRight,
    falCheck,
    falCircle,
    falEnvelope,
    falPhone,
    falTimes,

    farArrowLeft,
    farArrowRight,
    farBars,
    farTimes,
    farCircleArrowRight,

    fasBars,
    fasMinus,
    fasPhone,
    fasPlus,
    fasStar,
    fasTimes,
}