import GLightBox from 'glightbox'
import Alpine from 'alpinejs'
import { animate, stagger, inView, timeline } from "motion"
import SplitType from "split-type"

/**
 * Sentry
 */
import * as Sentry from "@sentry/browser";

Sentry.init({
    dsn: "https://2e05fc399e98adf5071be80946a2a7bd@o4508358341033984.ingest.de.sentry.io/4508399254437968",
    environment: process.env.NODE_ENV || 'development',
    integrations: [
        Sentry.replayIntegration(),
        Sentry.feedbackIntegration({
            // Additional SDK configuration goes in here, for example:
            colorScheme: "light",
            autoInject: window.showFeedback ?? false,
        }),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

/**
 * Font Awesome
 */
import { icons } from './icons.js'
import { library, dom } from '@fortawesome/fontawesome-svg-core'

library.add(icons);

dom.watch();

import Glide from '@glidejs/glide'

document.querySelectorAll('[data-glide]').forEach(glide => {
    new Glide(glide, {
        type: 'carousel',
        perView: 1,
        gap: 0,
        autoplay: 5000,
        hoverpause: true,
        animationDuration: 800,
        animationTimingFunc: 'cubic-bezier(0.165, 0.840, 0.440, 1.000)'
    }).mount();
});

document.querySelectorAll('[data-logo-carousel-glide]').forEach(glide => {
    new Glide(glide, {
        type: 'carousel',
        perView: 5,
        gap: 30,
        autoplay: 5000,
        hoverpause: true,
        animationDuration: 800,
        animationTimingFunc: 'cubic-bezier(0.165, 0.840, 0.440, 1.000)',
        breakpoints: {
            1360: {
                autoplay: 4000,
                perView: 4
            },
            960: {
                autoplay: 3000,
                perView: 3
            },
            720: {
                autoplay: 2000,
                perView: 2
            },
            360: {
                autoplay: 1200,
                perView: 1
            }
        }
    }).mount();
});

/**
 * Builder hero animations
 */
document.querySelectorAll("[data-hero]").forEach(hero => {
    let sequence = [];
    let title = hero.querySelector("[data-hero-title]");
    let titlePrefix = hero.querySelector("[data-hero-title-prefix]");

    if (titlePrefix) {
        let heroTitlePrefix = new SplitType(hero.querySelector("[data-hero-title-prefix]"), { types: 'words' });
        sequence.push([heroTitlePrefix.words,{x:[48,0],opacity:[0,1]},{ duration:1.5,delay:stagger(0.2)}]);
    }

    if (title) {
        let heroTitle = new SplitType(hero.querySelector("[data-hero-title]"), { types: 'words' });
        sequence.push([heroTitle.words,{y:[48,0],opacity:[0,1]},{ duration:1.5,delay:stagger(0.2),at:'-1.5'}]);
    }

    let subtitle = hero.querySelector("[data-hero-subtitle]");
    if (subtitle) {
        let heroSubTitle = new SplitType(hero.querySelector("[data-hero-subtitle]"), { types: 'lines' });
        sequence.push([heroSubTitle.lines,{y:[24,0],opacity:[0,1]},{ duration:1,delay:stagger(0.1),at:'-1.5'}]);
    }

    let buttons = hero.querySelectorAll('[data-hero-button]');
    if (buttons.length > 0) {
        sequence.push([buttons,{x:[24,0],opacity:[0,1]},{ duration:0.8,delay:stagger(0.1)}]);
    }

    inView(hero, ({ target }) => {
        timeline(sequence);
        if (titlePrefix) { titlePrefix.classList.remove('opacity-0'); }
        if (title) { title.classList.remove('opacity-0'); }
        if (subtitle) { subtitle.classList.remove('opacity-0'); }
        if (buttons.length > 0) {
            buttons.forEach(button => {
              button.classList.remove('opacity-0');
            });
        }
    });
});

/**
 * Builder card section animations
 */
document.querySelectorAll("[data-section-cards]").forEach(section => {
    let sequence = [];

    let title = section.querySelector("[data-title]");
    if (title) {
        let titleWords = new SplitType(title, { types: 'words' });
        title.style.opacity = 1
        sequence.push([titleWords.words, {y:[-24, 0], opacity: [0,1]}, { duration: 1, delay: stagger(0.1)}]);
    }

    let content = section.querySelector("[data-content]");
    if (content) {
        sequence.push([content, {opacity: [0,1], x:[100, 0]}, { duration: 1}]);
    }

    inView(section, ({ target }) => {
        timeline(sequence);
    },  { margin: "0px 0px -10% 0px" });

    let cards = section.querySelectorAll("[data-card]");

    if (cards.length > 0) {
        cards.forEach(card => {
            let cardSequence = [];
            let cardContentSequence = [];
            let cardImage = card.querySelector("[data-card-image]");
            let cardIcon = card.querySelector("[data-card-icon]");
            let cardTitle = card.querySelector("[data-card-title]");
            let cardContent = card.querySelector("[data-card-body]");

            cardSequence.push([card, {y: [50, 0], opacity:[0,1]}]);

            if (cardImage) {
                cardSequence.push([cardImage, {x: [20, 0], scale: [1.05, 1]}, { duration: 0.5}]);
            }

            if (cardIcon) {
                cardContentSequence.push([cardIcon, {opacity: [0,1], x: [24,0]}, { duration: 0.5}]);
            }

            if (cardTitle) {
                cardContentSequence.push([cardTitle, {opacity: [0,1], y: [24, 0]}, { duration: 0.5}]);
            }

            if (cardContent) {
                cardContentSequence.push([cardContent, {opacity: [0,1]}, {duration: 0.5}]);
            }

            inView(card, ({ target }) => {
                timeline(cardSequence);
            })

            inView(card.querySelector("[data-card-content]"), ({ target }) => {
                timeline(cardContentSequence);
            }, { margin: "0px 0px -10% 0px" })
        });
    }
});

/**
 * Builder grid section animations
 */
document.querySelectorAll("[data-section-grid]").forEach(section => {
    let sequence = [];

    let title = section.querySelector("[data-title]");
    if (title) {
        let titleWords = new SplitType(title, { types: 'words' });
        title.style.opacity = 1
        sequence.push([titleWords.words, {y:[-24, 0], opacity: [0,1]}, {duration: 1, delay: stagger(0.1)}]);
    }

    let content = section.querySelector("[data-content]");
    if (content) {
        sequence.push([content, {opacity: [0,1], x:[100,0]}, { duration: 2, at: "-0.7" }]);
    }

    inView(section, ({ target }) => {
        timeline(sequence);
    });

    let items = section.querySelectorAll("[data-grid-item]");

    if (items.length > 0) {
        items.forEach(item => {
            let itemSequence = [];
            let itemIcon = item.querySelector("[data-item-icon]");
            let itemTitle = item.querySelector("[data-item-title]");
            let itemContent = item.querySelector("[data-item-content]");
            let itemMore = item.querySelector("[data-item-more]");

            if (itemIcon) {
                itemSequence.push([ itemIcon, { opacity: [0,1], y:[80,0]}, { duration: 1 }]);
            }

            if (itemTitle) {
                itemSequence.push([ itemTitle, { opacity: [0,1], x: [48,0]}, { duration: 0.8, at: "-0.5" }]);
            }

            if (itemContent) {
                itemSequence.push([ itemContent, { opacity: [0,1], x: [50,0]}, { duration: 0.8, at: "-0.7" }]);
            }

            if (itemMore) {
                itemSequence.push([ itemMore, { opacity: [0,1], x: [50,0]}, { duration: 0.8, at: "-0.7" }]);
            }

            inView(item, ({ target }) => {
                timeline(itemSequence);
            }, { margin: "0px 0px -10% 0px" })

            let itemList = item.querySelector("[data-item-list]");

            if (itemList) {
                let itemListItems = item.querySelectorAll("[data-item-list] > li");
                inView(itemList, ({ target }) => {
                    timeline([
                        [itemList, { opacity: [0,1], y: [24,0]}, {duration: 0.1 }],
                        [itemListItems, { opacity: [0,1], x: [100,0]}, { duration: 1, delay: stagger(0.2) }]
                    ])
                }, { margin: "0px 0px -10% 0px" })
            }
        })
    }
});

/**
 * Build media panel animations
 */
document.querySelectorAll("[data-section-media-panel]").forEach(section => {

    let panel = section.querySelector("[data-panel]");

    if (panel) {
        let sequence = [];

        sequence.push([panel, {opacity: [0,1], x: [-24,0] }, { delay: 0.5, duration: 2} ]);

        let title = section.querySelector("[data-title]");
        if (title) {
            sequence.push([title, { opacity: [0,1], x: [24, 0] }, { duration: 1.5, at: "-1.4" }])
        }

        let content = section.querySelector("[data-content]");
        if (content) {
            sequence.push([content, {opacity: [0,1], x: [48,0]}, { duration: 2.5, at: "-1.3" }])
        }

        let contentImage = section.querySelector("[data-content-image]");
        if (contentImage) {
            sequence.push([contentImage, {opacity: [0,1], x: [48,0]}, { duration: 2.5, at: "-2" }])
        }

        inView(panel, ({ target }) => {
            timeline(sequence);
        });
    }
});

/**
 * Builder logo grid
 */
document.querySelectorAll("[data-section-logo-grid]").forEach(section => {
    let title = section.querySelector("[data-title]");
    if (title) {
        let titleWords = new SplitType(title, { types: 'words' });
        title.style.opacity = 1;
        inView(section, ({ target }) => {
            animate(titleWords.words, { y: [-24, 0], opacity: [0,1] }, { duration: 1, delay: stagger(0.1)});
        })
    }

    let logos = section.querySelectorAll("[data-logo-grid-item]");

    inView(section, ({target}) => {
        timeline([
            [logos, { y: [24, 0], scale: [.9,1], opacity: [0,1] }, { duration: 1, delay: stagger(0.07) }]
        ])
    })


    let cta = section.querySelector('[data-call-to-action]');
    if (cta) {
        inView(cta, ({target}) => {
            animate(cta, { y: [48,0], opacity: [0,1]}, { duration: 1 });
        });
    }

});

/**
 * Banner Animations
 */
document.querySelectorAll("[data-section-banner]").forEach(section => {
    let image = section.querySelector("[data-image]");
    if (image) {
        inView(image, ({ target }) => {
            animate(image, { x: [150, 0], opacity: [0,1]}, { duration: 1.5, delay: 1.2 });
        })
    }

    let title = section.querySelector("[data-title]");
    if (title) {
        let titleWords = new SplitType(title, { type: 'words' });
        title.style.opacity = 1
        inView(title, ({ target }) => {
            animate(titleWords.words, { y: [-24, 0], opacity: [0,1] }, { duration: 1, delay: stagger(0.1) });
        });
    }

    let subtitle = section.querySelector("[data-subtitle]");
    if (subtitle) {
        inView(subtitle, ({ target }) => {
            animate(subtitle, { x: [100, 0], opacity: [0,1] }, {duration: 1, delay: 1.2 });
        })
    }
});

window.Alpine = Alpine
Alpine.start()

/**
 * A counter that counts up to a target value
 * Use on the statistics block
 *
 * @param {*} target
 * @param {*} elementId
 */
function countUpTo(element) {
    let target = parseInt(element.dataset.counter);
    let currentValue =  0;
    const unit = element.dataset.counterUnit;

    function step() {
        currentValue += Math.round(Math.max(1, target /  100)); // Slow down towards the end

        element.textContent = currentValue + unit;

        if (currentValue < target) {
            window.requestAnimationFrame(step); // Call next frame
        }
    }

    step(); // Start counting
}

// Usage:

const countersOnPage = document.querySelectorAll('[data-counter]');

countersOnPage.forEach(counter => {
    let options = {
        root: null,
        rootMargin: '-10%',
        threshold: 0.5
    };

    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                countUpTo(counter);
            }
        });
    }, options);

    observer.observe(counter);
});

const lightbox = GLightBox();